<template>
  <div class="page-container" id="news">
    <div class="section-wrapper-wider">
      <h1 class="text-3xl pt-4 pb-2">News</h1>
      <h3 class="text-xl"></h3>

      <div class="flex grid grid-col-1 gap-2">


        <div class="news-box border-rose-100 flex grid grid-cols-6 gap-1">


          <div class="col-span-6 md:col-span-2 news-box-image bg-no-repeat bg-auto">
            <img src="/img/news/galacticare.jpg" class="h-auto w-auto"/>
          </div>


          <div class="col-span-6 md:col-span-4 pl-2">

            <h3>Galacticare is Now Available</h3>
            <p class="news-text">
              We’re very excited to announce that Galacticare from Brightrock Games was released today!
              <br><br>
              The player gets to be the Director of “Galacticare”, a space-based hospital, to manage the resources of their own interstellar healthcare company and to (try to) save the galaxy! The game is packed with humour, beautifully detailed visuals, and (if we say so ourselves) lovely audio!
              <br><br>
              We designed the sound effects and implemented audio for this game and absolutely loved working with the hugely talented development team at Brightrock Games. We hope you enjoy our work!
              <br><br>
              You can play Galacticare on <a class="news-link" href="https://store.steampowered.com/app/494730/Galacticare/" target="_blank">Steam</a>, <a class="news-link" href="https://www.xbox.com/en-gb/games/store/galacticare/9pb14ld018sm" target="_blank">Xbox</a>, <a class="news-link" href="https://store.playstation.com/en-gb/concept/10008023/" target="_blank">Playstation</a> and <a class="news-link" href="https://GOG.com" target="_blank">GOG.com.</a>
              <br><br>
              

            </p>

          </div>

        </div>



        <div class="news-box border-rose-100 flex grid grid-cols-6 gap-1">

          <div class="col-span-6 md:col-span-2 news-box-image bg-no-repeat bg-auto">
            <img src="/img/news/peaky.png" class="h-auto w-auto"/>
          </div>


          <div class="col-span-6 md:col-span-4 pl-2">



            
            <h3>Peaky Blinders Game Launched</h3>
            <p class="news-text">
              <i>Peaky Blinders: The King’s Ransom</i> is now available worldwide on Meta Quest 2 and Pico 4 headsets!
              <br><br>
              Developed by Maze Theory, the game places the player inside the gritty underworld of the hit TV series, Peaky Blinders.
              <br><br>
              Designing the audio for 1920’s Birmingham was a mammoth challenge, as it was important to ensure that the sound design remained fresh and original, whilst also remaining authentic to the TV series. We designed all the sound effects and audio systems in the game with this in mind. It was also a pleasure implementing the fantastic score from the game’s composer, Richard Wilkinson.
              <br><br>
              The audio has been getting some great comments.
              <br><br>


                <i class="news-testimonial">"Walking down Garrison Lane to the sound of “Red Right Hand” is magical, as in the show itself, and, because it is at the beginning, causes an excellent first impression for sound design, but not only that, the moments of tension, the lines of the characters and among others are very well demarcated."</i>
                  <br>
                  <span class="news-source">- mundogamer.community</span>
                <br><br>


              <i class="news-testimonial">"Sound design is the best part; nothing topped the moment of walking down Garrison Lane with “Red Right Hand” playing. They also brought over more of the smaller themed music and sounds from the show, which helped with the immersion."</i>
              <br>
              <span class="news-source">- waytoomany.games</span>

              <br><br>
              <i class="news-testimonial">"Sitting opposite Tommy Shelby in the Garrison Pub feels as atmospheric as you’d want it to, with the sounds of the pub around you, a glass of the good stuff in your digital hand.""</i>
              <br>
              <span class="news-source">- godisageek.com</span>

              <br><br>
              <i class="news-testimonial">
              "...environments look a lot more authentic, and the soundscape and voice acting do a great job of placing you inside Birmingham as well."</i>
              <br>
              <span class="news-source">- pressplaynews.net</span>

              <br><br>
              <i class="news-testimonial">
              "...the sounds of 1928’s Birmingham is portrayed well in each setting keeping you inside the world of Peaky Blinders."</i>
              <br>
              <span class="news-source">- thevrrealm.com</span>
              <br><br>
            </p>

          </div>

        </div>



        <div class="news-box border-rose-100 flex grid grid-cols-6 gap-1">


          <div class="col-span-6 md:col-span-2 news-box-image bg-no-repeat bg-auto">
            <img src="/img/news/blink.png" class="h-auto w-auto"/>
          </div>


          <div class="col-span-6 md:col-span-4 pl-2">

            <h3>BLINNK and the Vacuum of Space Launched</h3>
            <p class="news-text">
              We’ve been busy working with Dundee based developer Changingday on their autism-friendly VR game BLINNK and the Vacuum of Space.
              <br><br>
              Alongside composer and sound designer Rachel Simpson, we designed and implemented sound effects for the game to compliment the wonderful score by Rachel, Gavin Harrison and Luciano Rossi.
              <br><br>
              It’s been a fantastic experience, with the challenge of ensuring that the audio is suitable for neurodiverse players throughout. We needed to be particularly careful to make sure that the sounds created weren’t triggering for those sensitive to particular kinds of noise. The game features a calm and soothing audio “safe space” for those feeling overwhelmed.
              <br><br>
              We highly recommend giving this great game a try: <a class="news-link" href="https://store.steampowered.com/app/1815540/BLINNK_and_the_Vacuum_of_Space/" target="_blank">available on Steam now.</a>
            </p>

          </div>

        </div>




        <div class="news-box border-rose-100 flex grid grid-cols-6 gap-1">

          <div class="col-span-6 md:col-span-2 bg-rose-100 news-box-image">
            <div class="embed-youtube">
                <iframe src="//www.youtube.com/embed/QaQvobLYvmY" width="750" height="563"></iframe>
            </div>
          </div>

          <div class="col-span-6 md:col-span-4 pl-2">
            <h3>Sound Design for <i>Peaky Blinders: The King's Ransom</i></h3>
            <p class="news-text">​It's an absolute honour to be working with the incredibly talented team at Maze Theory on the sound design for their upcoming VR title <i>Peaky Blinders: The King's Ransom</i>. We at Zimmer Audio Production Ltd are designing a soundscape which is immersive, gritty and true to the series. We can't wait for everyone to hear it!</p>
          </div>

        </div>

        <div class="news-box border-rose-100 flex grid grid-cols-6 gap-1">
          <div class="col-span-6 md:col-span-2 bg-rose-100 news-box-image" style="background:url('img/news/iga_award.jpg'); background-size:cover;"></div>
          <div class="col-span-6 md:col-span-4 pl-2">
            <h3>IGA Mobile "Product of the Year" Award</h3>
            <p class="news-text">​Congratulations to ZAP!'s longstanding client, PLAY'n GO, who have won the International Gaming Award for mobile "Product of the Year". This category is for the developer whose games have shown "the very latest cutting edge technology", and ZAP! is proud to have created the audio for these excellent games.</p>
          </div>
        </div>

        <div class="news-box border-pink-100 flex grid grid-cols-6 gap-1">
          <div class="col-span-6 md:col-span-2 bg-rose-100 news-box-image" style="background:url('img/news/morton_valence.jpg'); background-size:cover;"></div>
          <div class="col-span-6 md:col-span-4 pl-2">
            <h3><span class="italic pr-1">Left</span> receives excellent reviews!</h3>
            <p class="news-text">A while ago, I mentioned that I was mastering <a class="news-link" href="http://www.mortonvalence.com/" target="_blank">Morton Valence's</a> latest album <span class="italic">Left</span>. I'm pleased to say that the album was released to critical acclaim, including a 4 Star review from Mojo magazine.</p>
          </div>
        </div>

        <div class="news-box border-fuchsia-100 flex grid grid-cols-6 gap-1">
          <div class="col-span-6 md:col-span-2 bg-rose-100 news-box-image" style="background:url('img/news/motor_museum.jpg'); background-size:cover; background-position:50% 50%;"></div>
          <div class="col-span-6 md:col-span-4 pl-2 overflow-scroll">
            <h3>Zimmer Audio at Beaulieu Motor Museum</h3>
            <p class="news-text">
              I finished work on the composition of an orchestral soundtrack for Beaulieu Motor Museum, to accompany a rolling presentation that will be seen by tens of thousands of visitors. The soundtrack is part of an audio-visual experience called <i>For Britian & for the Hell of It</i>, which tells the story of British land speed record triumphs over the decades. I had the huge privilege of working with two immensely talented guys on the piece,
              <a href="https://bozas.com/" target="_blank" class="news-link">Rob Bozas</a>
              and
              <a class="news-link" href="https://en.wikipedia.org/wiki/Stuart_Bruce_(engineer)" target="_blank">Stuart Bruce</a>,
              who between them have an astonishing list of credits - having worked with Peter Gabriel, Stevie Wonder and Bob Marley, to name just a few. My friend and longtime collaborator
              <a class="news-link" target="_blank" href="https://www.imdb.com/name/nm0285982/">Richard Fordham</a>
              also put in some great work on the sound design.
            </p>
          </div>
        </div>
        <!-- <div class="news-box border-purple-200">Headline Two</div>
        <div class="news-box border-violet-200">Headline Three</div>
        <div class="news-box border-indigo-200">Headline Four</div>
        <div class="news-box border-blue-200">Headline Five</div>
        <div class="news-box border-cyan-200">Headline Six</div> -->
      </div>

    </div>
  </div>
</template>

<script>
import {
    gsap
} from "gsap";

import {
    ScrollTrigger
} from "gsap/ScrollTrigger";


export default {
  mounted() {

        // a loop to trigger each element when it comes in to view
        const newsBoxes = gsap.utils.toArray('.news-box');
        const newsBoxInfo = gsap.utils.toArray('.news-box > div > p');
        const newsBoxImage = gsap.utils.toArray(".news-box > .news-box-image");
        const newsBoxTitle = gsap.utils.toArray(".news-box > div > h3");

        // newsBoxes.forEach(box => {
        //   gsap.fromTo(box,
        //   {
        //     x: 300,
        //   },
        //   {
        //     x: 0,
        //     scrollTrigger: {
        //       trigger: box,
        //       toggleActions: "play pause resume reverse",
        //       //scrub: true,
        //     }
        //   })
        // });
        //
        // newsBoxInfo.forEach(infoArea => {
        //   gsap.fromTo(infoArea,
        //   {
        //     y: 100,
        //     opacity:0,
        //   },
        //   {
        //     y: 0,
        //     opacity:1,
        //     duration:0.5,
        //     scrollTrigger: {
        //       trigger: infoArea,
        //       toggleActions: "play pause resume reverse",
        //       //scrub: true
        //     }
        //   })
        // });
        //
        // newsBoxImage.forEach(image => {
        //   gsap.fromTo(image,
        //   {
        //     rotateY: 100,
        //     opacity:0,
        //   },
        //   {
        //     rotateY: 0,
        //     opacity:1,
        //     scrollTrigger: {
        //       trigger: image,
        //       toggleActions: "play pause resume reverse",
        //       //scrub: true
        //     }
        //   })
        // });

  }
}
</script>

<style class="scss">


h2 {
  @apply text-center;
  font-family: 'Andale Mono', cursive;
}

.embed-youtube {
    position: relative;
    padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
    /* padding-bottom: 62.5%; - 16:10 aspect ratio */
    /* padding-bottom: 75%; - 4:3 aspect ratio */
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.embed-youtube iframe,
.embed-youtube object,
.embed-youtube embed {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

h3 {
  @apply text-xl !important;
}

.news-testimonial {
  @apply pb-6;
}

.news-source {
  @apply font-semibold text-sm;
}
</style>
